<template>
  <div class='d-flex align-items-center name-tile'>
    <template v-if='hideImage===false'>

      <img v-if='image && showImage' :src=image :alt=name @error='onError' class='table-field-img'>
      <img v-else-if='defaultImage && !showImage' src='@/assets/images/default.png' :alt=name @error='onError'
           class='table-field-img'>
      <div v-else class='table-field-img'>
     <span>
        {{ trimName[0] }}
     </span>
      </div>
    </template>
    <div style='display: flex;flex-direction: column;justify-content: start;align-items: start;gap:4px'>

      <span class='text-font-main mx-3'>{{ trimName }}</span>
      <span class='text-font-secondary mx-3' v-if='phone!=null'>{{ phone }}</span>
    </div>

  </div>
</template>

<script>

export default {
  name: 'NameTile',
  data() {
    return { showImage: true };
  },
  props: {
    name: null,
    image: null,
    phone: null,
    defaultImage: {
      default: false,
      type: Boolean
    },
    hideImage: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    trimName() {
      if (this.name) {
        return this.name.toString().trim();
      }
      return '';
    }

  },
  watch: {
    image: {
      immediate: true,
      handler() {
        this.showImage = true;
      }
    }
  },
  methods: {
    onError() {
      this.showImage = false;
    }
  }


};
</script>

